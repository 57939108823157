import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Banner from "../../images/Product/product-banner.jpg";
// import TableP from "../../images/Product/Tables-Ottomans/table/img1.jpg";
// import ProductModal from "../../components/ProductModal";
import "firebase/auth";
import {
  AuthCheck,
  useFirebaseApp,
  useFirestore,
  useFirestoreCollectionData,
} from "reactfire";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { navigate } from "gatsby-link";
import { Link } from "gatsby";
import ReactPaginate from "react-paginate";

import SliderZoom from "../../SliderImage/index";
// import ImageCarousel from "../../SliderImage/SliderImage2";
import AsNavFor from "../../components/ProductModal";

function Product(props) {
  const { buttonLabel, className } = props;

  const firebase1 = useFirebaseApp();
  const firestore = useFirestore(firebase1);
  const firebaseStorage = firebase1.storage();

  const catRef = firestore.collection("Products").where("enabled", "==", true);

  let { data } = useFirestoreCollectionData(catRef);
  const [product, setProduct] = useState({});

  const [showModal, setShowModal] = useState(true);
  const toggle = () => setShowModal(!showModal);

  let signedIn = false;
  //

  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const imageZoomContainer = useRef(null);
  const [nav1, setnav1] = useState(null);
  const [nav2, setnav2] = useState(null);

  const postsPerPage = 20;

  const [pageNumber, setPageNumber] = useState(0);
  const pagesVisited = pageNumber * postsPerPage;
  const [productList, setProductList] = useState([]);
  let productListData = [];

  useEffect(() => {
    setnav1(slider1.current);
    setnav2(slider2.current);
  }, []);

  // console.log("Produc: " + JSON.stringify(props.location.state));

  // if (!props.location.state) {
  //   <Link to="/" state={{ comingFromProduct: "true" }} />;
  // }
  useEffect(() => {
    if (props.location.state != null && props.location.state.product != null) {
      const getProductName = props.location.state.product;

      if (data) {
        console.log("Filter1: " + JSON.stringify(data));
        const filteredProductData = data.filter((product) =>
          product.category == getProductName ? product : ""
        );

        setProductList(filteredProductData);
      } else {
        setProductList([]);
      }
    } else {
      navigate("../");
    }
  }, [data, props.location.state && props.location.state.product]);

  const getProducstList = (prodData) => {
    console.log("Filtered Product Data: " + JSON.stringify(productList));

    return productList
      .slice(pagesVisited, pagesVisited + postsPerPage)
      .map((product, index) => {
        // console.log("Category: " + getProductName);

        // if (product.category == getProductName) {
        const getImages = product.imagesUrl;

        console.log("Data: " + getImages);

        if (getImages != undefined) {
          let indexesOfImages = [];

          getImages.forEach((item, index) => {
            if (item != undefined) {
              const imagesref = firebaseStorage.refFromURL(item);
              const result = imagesref.name.split("-").pop().split(".")[0];
              console.log("Result112: " + result + "Product: " + product.name);
            
                console.log(
                  "Result332: " + result + "Product: " + product.name
                );
                indexesOfImages[result] = item;
             
            }
          });

          // console.log("Array of Indexes: " + indexesOfImages);

          // const getFirstIndexImage = getImages.filter((x, index) => {
          //   if (x != undefined) {
          //     const imagesref = firebaseStorage.refFromURL(x);
          //     const result = imagesref.name.split("-").pop().split(".")[0];

          //     return result;
          //   }
          // });

          product.imagesUrl = indexesOfImages;

          // console.log("Images Name: " + JSON.stringify(getFirstIndexImage));

          return (
            <Col md="3">
              <div
                onClick={() => {
                  onClickHandler(product);
                }}
                className="min_use_product"
              >
                <div className="use_product">
                  <img src={indexesOfImages[0]} alt="image" />
                </div>
                <div className="use_product_text">
                  <h5>{product.name}</h5>
                </div>
              </div>
            </Col>
          );
        } else {
          return null;
        }
        // }
      });
  };

  const onClickHandler = (product) => {
    // console.log("Clicked: " + JSON.stringify(product));
    setProduct(product);

    setShowModal(true);
  };

  const ProductDetailModal = (props) => {
    const imgRef = useRef();
    const paneRef = useRef();
    const inlineContainerRef = useRef();
    // let dataArray = [];
    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {
      let dataArray = props.productData.imagesUrl.map((imageUrl, index) => ({
        image: imageUrl,
        text: "",
      }));

      setDataArray(dataArray);

      // console.log("Product Data112: "+JSON.stringify(data));
    }, []);

    return (
      <Modal isOpen={showModal} className="product_modal">
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <Row>
            <Col md="7">
              <div className="sliderImage">
                {/* <SliderZoom data={dataArray}  showDescription={false} direction="right" /> */}
                {/* <ImageCarousel productImages={props.productData.imagesUrl}  showDescription={false} direction="right" /> */}

                <AsNavFor productImages={props.productData.imagesUrl} />
              </div>
            </Col>
            <Col md="5">
              <div className="product_text" ref={imageZoomContainer}>
                <h2>{props.productData.name}</h2>
                {/* <p>More Information</p>
                                        <hr/> */}
                <h6>Product SKU/Diminesions</h6>
                <hr />
                <p>{props.productData.dimensions}</p>

                <h6>Finishes</h6>
                <hr />
                <p>{props.productData.finishes}</p>

                <Button href="/ContactUs">Get Quote</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="description_main">
                <h6>Description</h6>
                <div className="product_description">
                  <p>{props.productData.description}</p>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  // if (status === "loading") {
  //   console.log("Loading...");
  //   signedIn = false;
  //   return <Loader />;
  // } else {
  //   if (signInCheckResult.signedIn === true) {
  //     signedIn = true;
  //   }
  // }

  // if (signedIn === false) {
  //   navigate("/Login");
  //   return null;
  // } else {
  const pageCount = Math.ceil(productList && productList.length / postsPerPage);

  const handlePageClick = (data) => {
    console.log("Page Click: " + pageCount);

    let selected = data.selected;
    setPageNumber(selected);
  };

  if (window.localStorage.getItem("isSignedIn") == "true") {
    return (
      <Layout>
        <section className="product_banner">
          <Container>
            {/* <Row>
                        <Col md="4"> */}
            <div>
              <img src={Banner} alt="img" />
            </div>
            {/* </Col>
                    </Row> */}
          </Container>
        </section>
        <section className="product_section_main">
          <Container>
            {console.log("Product " + product.category)}
            {product.category != undefined ? (
              <ProductDetailModal productData={product} />
            ) : (
              console.log("Rendered")
            )}{" "}
            <Row>{data != undefined && getProducstList(data)}</Row>
          </Container>
        </section>
        <section>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={100}
            onPageChange={handlePageClick}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            containerClassName={"pagination"}
          />
        </section>
      </Layout>
    );
  } else {
    navigate("/Login");
    return null;
  }
}

// }

export default Product;
