import React, { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";


/* css */
import "./styles.css";

let cx = 0;
let cy = 0;
let lensCurrent = null;
let imageCurrent = null;
let resultCurrent = null;

const SilderImage = ({ data, width, showDescription, direction }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const carouselItemsRef = useRef([]);

  const [index, setIndex] = useState(0);
  const [indexCount, setIndexCount] = useState(0);

  // const selectedImage = data[index];
  const refImage = useRef();
  const refLens = useRef();
  const refResult = useRef();

  const [showButtons, setShowButtons] = useState(false);
  let slider1 = useRef(null);
  let slider2 = useRef(null);
  const [nav1, setnav1] = useState(null);
  const [nav2, setnav2] = useState(null);

  useEffect(() => {
    setnav1(slider1.current);
    setnav2(slider2.current);
  }, []);

  // useEffect(() => {
  //   if (data && data[0]) {
  //     carouselItemsRef.current = carouselItemsRef.current.slice(0, data.length);

  //     setSelectedImageIndex(0);
  //     setSelectedImage(data[0]);
  //   }
  // }, [data]);

  // const handleSelectedImageChange = (newIdx) => {
  //   if (data && data.length > 0) {
  //     setSelectedImage(data[newIdx]);
  //     setSelectedImageIndex(newIdx);
  //     console.log("Index: "+newIdx);
  //     if (carouselItemsRef?.current[newIdx] && newIdx == 4) {
  //       carouselItemsRef?.current[newIdx]?.scrollIntoView({
  //         inline: "center",
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  // };

  // const handleRightClick = () => {
  //   if (data && data.length > 0) {
  //     let newIdx = selectedImageIndex + 1;
  //     if (newIdx >= data.length) {
  //       newIdx = 0;
  //     }
  //     handleSelectedImageChange(newIdx);
  //   }
  // };

  // const handleLeftClick = () => {
  //   if (data && data.length > 0) {
  //     let newIdx = selectedImageIndex - 1;
  //     if (newIdx < 0) {
  //       newIdx = data.length - 1;
  //     }
  //     handleSelectedImageChange(newIdx);
  //   }
  // };

  // return (
  //   <div className="carousel-container">
  //     <div
  //       className="selected-image"
  //       style={{ backgroundImage: `url(${selectedImage?.image})` }}
  //     />
  //     <div className="carousel">
  //       <div className="carousel__images">
  //         {data &&
  //           data.map((image, idx) => (
  //             <div
  //               onClick={() => handleSelectedImageChange(idx)}
  //               key={image.id}
  //               style={{ backgroundImage: `url(${image.image})` }}
  //               className={`carousel__image ${
  //                 selectedImageIndex === idx && "carousel__image-selected"
  //               }`}
  //               ref={(el) => (carouselItemsRef.current[idx] = el)}
  //             >
  //               {/* <img src={image.image} /> */}
  //             </div>
  //           ))}
  //       </div>
  //       <button
  //         className="carousel__button carousel__button-left"
  //         onClick={handleLeftClick}
  //       >
  //         Prev
  //       </button>
  //       <button
  //         className="carousel__button carousel__button-right"
  //         onClick={handleRightClick}
  //       >
  //         Next
  //       </button>
  //     </div>
  //   </div>
  // );


useEffect(() => {
  if (data && data[0]) {
    // slider1.current = slider1.current.slice(0, data.length);
    setIndex(0);
    setSelectedImage(data[0]);
  }
}, [data]);

const handleSelectedImageChange = (newIdx) => {
  if (data && data.length > 0) {
    setSelectedImage(data[newIdx]);
    setIndex(newIdx);
    console.log("Index: " + newIdx);
    if (carouselItemsRef?.current[newIdx]) {
      carouselItemsRef?.current[newIdx]?.scrollIntoView({
        inline: "center",
        behavior: "smooth",
      });
    }
  }
}

  useEffect(() => {
    console.log(
      "Index Mod: " +
        (index % 4) +
        " Index: " +
        index +
        " Index Div: " +
        index / 4 +
        " Data Length: " +
        data.length +
        " Index: " +
        index
    );

    if (index % 4 == 0 && index / 4 != 0) {
      slider2.slickNext();
    } else if (index == 0) {
      slider2.slickGoTo(0);
    }
  }, [index]);

  console.log("Index Count: " + indexCount);

  function getCursorPos(e, imageCurrent) {
    let x = 0;
    let y = 0;
    const getBoundingImage = imageCurrent.getBoundingClientRect();

    e = e || window.event;
    x = e.pageX - getBoundingImage.left - window.pageXOffset;
    y = e.pageY - getBoundingImage.top - y - window.pageYOffset;

    return { x, y };
  }

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <IoIosArrowForward
        size={60}
        className={className}
        style={{
          ...style,
          color: "black",
          width: "50px",
          height: "40px",
          marginRight: "-67px",
        }}
        onClick={onClick}
      />
    );
  };
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <IoIosArrowBack
        size={60}
        className={className}
        style={{
          ...style,
          color: "black",
          width: "50px",
          height: "40px",
          marginLeft: "-67px",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    slidesToScroll: 4,
    infinite: false,
    slidesToShow: 4,
    focusOnSelect:true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    lensCurrent = refLens.current;
    imageCurrent = refImage.current;
    resultCurrent = refResult.current;

    function moveLens(
      e,
      imageWidth,
      imageHeight,
      offsetWidthLens,
      offsetHeightLens
    ) {
      e.preventDefault();
      let x = 0;
      let y = 0;

      const pos = getCursorPos(e, imageCurrent);
      x = pos.x - offsetWidthLens / 2;
      y = pos.y - offsetHeightLens / 2;

      if (x > imageWidth - offsetWidthLens) {
        x = imageWidth - offsetWidthLens;
      }
      if (x < 0) {
        x = 0;
      }
      if (y > imageHeight - offsetHeightLens) {
        y = imageHeight - offsetHeightLens;
      }
      if (y < 0) {
        y = 0;
      }

      lensCurrent.style.left = `${x}px`;
      lensCurrent.style.top = `${y}px`;
      resultCurrent.style.backgroundPosition = `-${x * cx}px -${y * cy}px`;
    }
    if (imageCurrent !== null) {
      const imageWidth = imageCurrent.width;
      const imageHeight = imageCurrent.height || imageWidth - 120;
      const offsetWidthLens = lensCurrent.offsetWidth;
      const offsetHeightLens = lensCurrent.offsetHeight;

      cx = resultCurrent.offsetWidth / offsetWidthLens;
      cy = resultCurrent.offsetHeight / offsetHeightLens;
      resultCurrent.style.backgroundSize = `${imageWidth * cx}px ${
        imageHeight * cy
      }px`;

      // imageCurrent.addEventListener("mousemove", (e) => setShowButtons(true));

      imageCurrent.addEventListener("mousemove", (e) =>
        moveLens(e, imageWidth, imageHeight, offsetWidthLens, offsetHeightLens)
      );
      lensCurrent.addEventListener("mousemove", (e) =>
        moveLens(e, imageWidth, imageHeight, offsetWidthLens, offsetHeightLens)
      );
    }

    return () => {
      imageCurrent.removeEventListener("mousemove", setShowButtons(false));

      imageCurrent.removeEventListener("mousemove", moveLens);
      lensCurrent.removeEventListener("mousemove", moveLens);
    };
  }, []);

  useEffect(() => {
    imageCurrent.addEventListener("mousemove", (e) => setShowButtons(true));
  }, []);

  const onPrevSlider =() => {


    if(data && data.length > 0){
      let newIndex = index - 1;
      setIndex((prev) => prev - 1 < 0 ? data.length - 1 : prev - 1);
    }

    // if (data && data.length > 0) {
    //   let newIdx = selectedImageIndex - 1;
    //   if (newIdx < 0) {
    //     newIdx = data.length - 1;
    //   }
    //   handleSelectedImageChange(newIdx);
    // }
  }

  const onNextSlider = () => {

    if(index != data.length - 1){
    if(data && data.length > 0){
      console.log("Next Index: "+index);
      setIndex((prev) => prev + 1 != 0 ? prev + 1 : 0);
    }
  }else{
    setIndex(0)
  }

    
    // if (data && data.length > 0) {
    //   let newIdx = selectedImageIndex + 1;
    //   if (newIdx >= data.length) {
    //     newIdx = 0;
    //   }
    //   handleSelectedImageChange(newIdx);
    // }
  }

  return (
    <div className="react-slider" style={{ width: width || "auto" }}>
      <div
        className="react-slider__container"
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        <div className="react-slider__areaZoom">
          <div className="react-slider__lens" ref={refLens} />
          <div className="react-slider__picture">
            {console.log("Image Index: "+index)}
            <img
              src={data[index].image}
              alt={data[index].image}
              ref={refImage}
            />
          </div>
          <div
            ref={refResult}
            className="react-slider__imgZoom"
            style={{
              backgroundImage: `url(${selectedImage?.image})`,
              left: `${direction === "left" && "-107%"}`,
            }}
          />
        </div>

        {showButtons ? (
          <button
            type="button"
            className="react-slider__btnPrev"
            onClick={onPrevSlider}
          />
        ) : null}

        {showButtons ? (
          <button
            type="button"
            className="react-slider__btnNext"
            onClick={onNextSlider}
          />
        ) : null}
        {/* {showDescription && (
          <div className="react-slider__description">{selectedImage.text}</div>
        )} */}
      </div>{" "}
      
      <div
        style={{
          width: "100%",
          display: "block",
          padding: "20px",
        }}
      >
        <Slider
          {...settings}
          ref={(c) => (slider2 = c)}
          className="product_modal"
          asNavFor={nav1}
        >
          {data.map((item, idx) => (
            <div
              className="sliderImage"
              key={idx}
             
              onClick={() => setIndex(idx)}
            >
              <img src={item.image} alt=""/>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};


SilderImage.propTypes = {
  /** data */
  data: PropTypes.array.isRequired,
  /** left | right */
  direction: PropTypes.string,
  /** show description of image */
  showDescription: PropTypes.bool,
  /** set size slider image */
  width: PropTypes.string,
};

SilderImage.defaultProps = {
  direction: "right",
  showDescription: false,
  width: "auto",
};

export default SilderImage;
