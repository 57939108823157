import React, { Component } from "react";
import Slider from "react-slick";
import Product1 from "../images/Product/Tables-Ottomans/table/img1.jpg";
import Product2 from "../images/Product/Tables-Ottomans/table/img2.jpg";
import Product3 from "../images/Product/Tables-Ottomans/table/img3.jpg";
import Product4 from "../images/Product/Tables-Ottomans/table/img4.jpg";
import Product5 from "../images/Product/Tables-Ottomans/table/img5.jpg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReactImageMagnify from "@milosmladenovicwork/react-image-magnify";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <IoIosArrowForward
      size={60}
      className={className}
      style={{
        ...style,
        color: "black",
        width: "50px",
        height: "40px",
        marginRight: "-67px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <IoIosArrowBack
      size={60}
      className={className}
      style={{
        ...style,
        color: "black",
        width: "50px",
        height: "40px",
        marginLeft: "-67px",
      }}
      onClick={onClick}
    />
  );
}
export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      productImages: props.productImages,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    console.log("Products: " + this.state.productImages);

    const settings = {
      // dots: true,
      infinite: false,
      // slidesToShow: 3,
      // slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    const props = {width: 400, height: 250, zoomWidth: 500};

    return (
      <div>
         <div className="react-slider__areaZoom">
        <Slider
          {...settings}
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          className="product_modal product_modal2"
        >
          {this.state.productImages.map((imageUrl) => {
            return (
              <div>
                <div>
                  {/* <SideBySideMagnifier className="magnifier" imageSrc={imageUrl} alwaysInPlace={false} imageAlt="Image1" /> */}
                  {/* <img src={imageUrl} alt="img" /> */}

                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src:  imageUrl ,
                      },
                      largeImage: {
                        src:  imageUrl ,
                        width: 1500,
                        height: 1500,
                      },

                      enlargedImagePosition: "beside",
                      enlargedImagePortalId: "react_zoom",
                        enlargedImageContainerDimensions: {
                          width: "100%",
                          height: "100%",
                        },
                        shouldUsePositiveSpaceLens: true
                    }}
                  />

                </div>
                
              </div>
            );
          })}
        </Slider>

        <div
            id="react_zoom"
            className="react-slider__imgZoom"
            
          />
          </div>
        <Slider
          {...settings}
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          slidesToShow={4}
          swipeToSlide={true}
          focusOnSelect={true}
          className="product_modal product_modal1"
        >
          {this.state.productImages.map((productImages) => {
            console.log("Images113: " + productImages);
            return (
              <div>
                <div className="product_itm_smale ">
                  <img src={productImages} alt="img" />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
